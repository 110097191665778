import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Header from "../components/header";
import Layout from "../components/layout"
import SEO from "../components/seo"
import TableOfContents from "./tableOfContents"
import PrevNextLinks from "./prevNextLinks"

const Grid = styled.div `
  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-gap: 40px;
  }
`;

export default function Template(props) {
  const { markdownRemark } = props.data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Header /> 
      <SEO title={frontmatter.title} />
      <Grid>
        <TableOfContents
          links={props.pageContext.pages}
          current={frontmatter.path}
        />
        <div>
          <h1 style={{ marginBottom: "40px" }}>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />

          <PrevNextLinks
            links={props.pageContext.pages}
            current={frontmatter.path}
          />
        </div>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql `
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date
        header
        title
      }
    }
  }
`