import React, { Component } from "react"
import styled from "styled-components";
import { Link } from "gatsby"
import { nest } from "d3-collection";

import { Collapse } from 'antd';
const { Panel } = Collapse;

const Wrapper = styled.div `
  border-right: 1px solid #ddd;
  padding-right: 40px;
  padding-top: 10px;
  ol {
    color: #888;
    margin-left: 10px;
  }
`;
const Line = styled.li `
  padding-left: 10px;
  font-size: 14px;
  margin-bottom: 2px;
  line-height: 1.5em;

  background: ${props => props.selected ? "rgba(102,143,210,.2)" : "transparent"};

  a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 2px 0;
  }
  @media (min-width: 640px) {
    :hover {
      background: rgba(102,143,210,.1);
    }
  }
`;

class TableOfContents extends Component {
  state = { narrow: false };

  componentDidMount() {
    if (window.innerWidth < 640) this.setState({ narrow: true })
  }

  render() {
    const { links, current } = this.props;
    const { narrow } = this.state;

    const posts = nest().key(d => d.header)
      .entries(links.sort((a, b) => new Date(a.date) - new Date(b.date)))

    if (narrow)
      return (
        <Collapse style={{ marginBottom: "40px" }}>
          <Panel header="Docs Navigation" key="1">
            <RenderPosts posts={posts} current={current} />
          </Panel>
        </Collapse>
      )
    else
      return (
        <Wrapper>
          <RenderPosts posts={posts} current={current} />
        </Wrapper>
      )
  }
}

const RenderPosts = ({ posts, current }) => (
  <div>
    {posts.map((p, i) => (
      <div key={`type${i}`}>
        <h4 style={{ marginBottom: "10px" }}>{p.key}</h4>
        <ol>
          {p.values.map((v,j) => (
            <Line key={`link${i}-${j}`} selected={v.path === current}>
              <Link to={v.path}>
                {v.title}
              </Link>
            </Line>
          ))}
        </ol>
      </div>
    ))}
  </div>
)

export default TableOfContents