import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";

const Wrapper = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  border-top: 1px solid #ddd;
  margin-top: 80px;
  padding-top: 40px;

  > div:last-child {
    text-align: right;
  }
  a {
    color: black;
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }
`;

const PrevNextLinks = ({ links, current }) => {
  const posts = links
    .sort((a, b) => new Date(a.date) - new Date(b.date))
  const currentIndex = posts.findIndex(l => l.path === current);

  return (
    <Wrapper>
      <div>
      { currentIndex > 0 && (
        <Link to={posts[currentIndex - 1].path}>
           &#10510; {posts[currentIndex - 1].title}
        </Link>
      )}
      </div>
      <div>
        { currentIndex < posts.length - 1 && (
        <Link to={posts[currentIndex + 1].path}>
          {posts[currentIndex + 1].title} &#10511;
        </Link>
      )}
      </div>
    </Wrapper>
  )
}

export default PrevNextLinks